import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { LiffProvider } from "react-liff";
import TagManager from "react-gtm-module";

import App from "./App";
import AppNotLine from "./AppNotLine";
import store from "./services/Store";
import config from "util/config";
import { PrimeReactProvider, PrimeReactContext } from "primereact/api";
import "./index.css";

const CONFIG = config.config();

let initialized = false;

const fnHandleLiff = (liff) => {
  // เช็ค liff เปิดจาก app only (prod)
  if (CONFIG.APP_ENV === "prod") {
    if (!liff.isInClient()) {
      window.location = "/error";
    }
  }
  if (initialized === false) {
    initialized = true;

    if (typeof liff.error !== "undefined") {
      console.warn("error", liff.error);
    }

    liff.ready.then(() => {
      console.log("ready :", liff.isLoggedIn());

      liff
        .getProfile()
        .then((profile) => {
          console.log("profile :", profile);
        })
        .catch((err) => {
          console.log("error :", err);
        });
    });
  }
};

const liffId = CONFIG.LIFF_ID;

let isLoginBrowser = true;

const tagManagerArgs = {
  gtmId: "GTM-MMC9G45J",
};

TagManager.initialize(tagManagerArgs);

const root = ReactDOM.createRoot(document.getElementById("root"));

const search = window.location.search;
const path = window.location.pathname;

if (
  search.includes("external-") ||
  path.includes("external-") ||
  path.includes("error")
) {
  root.render(
    <Provider store={store}>
      <AppNotLine />
    </Provider>
  );
} else {
  root.render(
    <PrimeReactProvider>
      <Provider store={store}>
        {/* <LiffProvider
        liffId={liffId}
        withLoginOnExternalBrowser={isLoginBrowser}
        callback={fnHandleLiff}
      > */}

        <App />
        {/* </LiffProvider> */}
      </Provider>
    </PrimeReactProvider>
  );
}
